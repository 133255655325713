<template>
  <div class="tips__main-container">
    <TipsFilter />
    <BetDataTable />
    <TipsAnalysis />
    <SponsorFooter v-if="!isSponsorHidden" />
  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex';

export default {
  name: 'Tips',
  components: {
    TipsFilter: () => import('@/components/Menu/TipsFilter'),
    BetDataTable: () => import('@/components/Elements/BetDataTable'),
    TipsAnalysis: () => import('@/components/Elements/TipsAnalysis'),
    SponsorFooter: () => import('@/components/Elements/SponsorFooter'),
  },
  props: {
    isSponsorHidden: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    ...mapState('loginGeneral', ['user']),
  },
  mounted() {
    this.fetchTournamentsWithScenarios();
  },
  methods: {
    ...mapActions('tips', ['fetchScenarios', 'fetchTournamentsWithScenarios']),
  },
};
</script>

<style lang="scss">
.tips__main-container {
  padding: 1rem;
  max-width: 1344px;
  margin: 0 auto;
}
.has-divider-vertical {
  height: 1rem;
  border-left: 1px dotted #bebcbc;
}
@media only screen and (max-width: 415px) {
  .tips__main-container {
    min-height: 430px;
    padding: 3px;
    max-width: 100%;
    margin: auto 2px;
  }
  ::-webkit-scrollbar {
    display: none;
  }
}
</style>
